document.addEventListener("DOMContentLoaded", () => {
  const referral_code = new URLSearchParams(window.location.search).get('r')

  if(referral_code !== null) {
    var img = document.createElement('img');
    img.src = 'https://simpleclient.app/referral_pixel.gif?r=' + referral_code
    img.style.cssText = 'opacity:0;height:0;width:0;'
    document.body.appendChild(img)
    setTimeout(function() {
      if( window.location.pathname == '/signup') {
        window.location.href = window.location.pathname
      }
    }, 10)
  }
})